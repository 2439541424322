/* src/App.css */

/* Define CSS Variables for Easy Theme Management */
:root {
  --primary-color: #4B92DB; /* Vibrant Blue */
  --secondary-color: #FFFFFF; /* White */
  --text-color: #000000; /* Black for body text */
  --nav-text-color: var(--secondary-color); /* White text in nav */
  --nav-hover-color: #e0e0e0; /* Light gray on hover */
  --dropdown-bg-color: #4B92DB; /* Same as primary */
  --dropdown-hover-bg-color: #3a7bbf; /* Darker blue on hover */
}

/* Apply Global Styles */
body {
  margin: 0;
  padding: 0;
  font-family: Arial, Helvetica, sans-serif;
  background-color: var(--secondary-color);
  color: var(--text-color);
}

/* App Container */
.App {
  text-align: center;
}

/* Navigation Bar */
.App-nav {
  background-color: var(--primary-color);
  padding: 10px 0;
  position: relative;
}

/* Navigation List */
.App-nav ul {
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
}

/* Navigation Items */
.App-nav li {
  margin: 0 15px;
  position: relative;
}

/* Dropdown Button */
.dropdown {
  position: relative;
}

/* Dropdown Toggle */
.dropdown > button {
  background: none;
  border: none;
  color: var(--nav-text-color);
  font-size: 18px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

/* Arrow Indicator */
.dropdown > button::after {
  content: ' ▼';
  font-size: 12px;
  margin-left: 5px;
}

/* Dropdown Content (hidden by default) */
.dropdown-content {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: var(--dropdown-bg-color);
  min-width: 160px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
  flex-direction: column;
}

/* Show the dropdown on hover */
.dropdown:hover .dropdown-content {
  display: flex;
}

/* Dropdown Items */
.dropdown-content a {
  color: var(--nav-text-color);
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
  transition: background-color 0.3s ease;
}

/* Dropdown Items on Hover */
.dropdown-content a:hover {
  background-color: var(--dropdown-hover-bg-color);
}

/* Navigation Links */
.App-nav a,
.App-nav button {
  color: var(--nav-text-color);
  text-decoration: none;
  font-size: 18px;
  transition: color 0.3s ease;
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
  font-family: inherit;
}

/* Navigation Links on Hover */
.App-nav a:hover,
.App-nav button:hover {
  color: var(--nav-hover-color);
}

/* Active Link Styling */
.active {
  border-bottom: 2px solid var(--nav-hover-color);
}

/* Main Content Area */
.App-content {
  padding: 20px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .App-nav ul {
    flex-direction: column;
    align-items: flex-start;
  }

  .App-nav li {
    margin: 10px 0;
  }

  .dropdown-content {
    position: static;
    box-shadow: none;
  }

  .dropdown-content a {
    padding: 10px 20px;
  }
}
