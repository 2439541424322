/* src/pages/ByzantineEmpireComponent.css */

/* Define CSS Variables for Easy Theme Management */
:root {
    --primary-color: #4B92DB; /* Vibrant Blue */
    --secondary-color: #FFFFFF; /* White */
    --text-color: #000000; /* Black for body text */
    --heading-color: #4B92DB; /* Same as primary or adjust as needed */
    --link-color: #4B92DB;
    --link-hover-color: #e0e0e0;
  }
  
  /* Apply Global Styles */
  .ByzantineEmpire {
    background-color: var(--secondary-color);
    color: var(--text-color);
    padding: 20px;
    font-family: Arial, Helvetica, sans-serif;
    max-width: 800px;
    margin: 0 auto;
    text-align: left;
  }
  
  /* Headings */
  .ByzantineEmpire h2 {
    color: var(--heading-color);
    margin-bottom: 15px;
  }
  
  .ByzantineEmpire h3 {
    color: var(--heading-color);
    margin-top: 20px;
    margin-bottom: 10px;
  }
  
  /* Paragraphs */
  .ByzantineEmpire p {
    line-height: 1.6;
    margin-bottom: 15px;
  }
  
  /* Links (if any) */
  .ByzantineEmpire a {
    color: var(--link-color);
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  .ByzantineEmpire a:hover {
    color: var(--link-hover-color);
  }
  