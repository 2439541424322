/* src/pages/RomanEmpireComponent.css */

/* Define CSS Variables for Easy Theme Management */
:root {
  --primary-color: #4B92DB; /* Vibrant Blue */
  --secondary-color: #FFFFFF; /* White */
  --text-color: #000000; /* Black for body text */
  --heading-color: #4B92DB; /* Same as primary or adjust as needed */
  --subheading-color: #c3070a; /* Roman Red */
  --link-color: #4B92DB;
  --link-hover-color: #e0e0e0;
}

/* Apply Global Styles */
.RomanEmpire {
  background-color: var(--secondary-color);
  color: var(--text-color);
  padding: 20px;
  font-family: Arial, Helvetica, sans-serif;
  max-width: 800px;
  margin: 0 auto;
  text-align: left;
}

/* Headings */
.RomanEmpire h2 {
  color: var(--heading-color);
  margin-bottom: 15px;
}

.RomanEmpire h3 {
  color: var(--subheading-color);
  margin-top: 20px;
  margin-bottom: 10px;
}

/* Paragraphs */
.RomanEmpire p {
  line-height: 1.6;
  margin-bottom: 15px;
}

/* Links (if any) */
.RomanEmpire a {
  color: var(--link-color);
  text-decoration: none;
  transition: color 0.3s ease;
}

.RomanEmpire a:hover {
  color: var(--link-hover-color);
}/* src/pages/RomanEmpireComponent.css */

/* Define CSS Variables for Easy Theme Management */
:root {
  --primary-color: #4B92DB; /* Vibrant Blue */
  --secondary-color: #FFFFFF; /* White */
  --text-color: #000000; /* Black for body text */
  --heading-color: #4B92DB; /* Same as primary or adjust as needed */
  --subheading-color: #8B0000; /* Dark Red */
  --link-color: #4B92DB;
  --link-hover-color: #e0e0e0;
}

/* Apply Global Styles */
.RomanEmpire {
  background-color: var(--secondary-color);
  color: var(--text-color);
  padding: 20px;
  font-family: Arial, Helvetica, sans-serif;
  max-width: 800px;
  margin: 0 auto;
  text-align: left;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

/* Headings */
.RomanEmpire h2 {
  color: var(--heading-color);
  margin-bottom: 15px;
  grid-column: span 2;
}

.RomanEmpire h3 {
  color: var(--subheading-color);
  margin-top: 20px;
  margin-bottom: 10px;
  grid-column: span 2;
}

/* Paragraphs */
.RomanEmpire p {
  line-height: 1.6;
  margin-bottom: 15px;
}

/* Image Styling */
.RomanEmpire img {
  width: 100%;
  max-width: 400px;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  justify-self: center;
}

/* Links (if any) */
.RomanEmpire a {
  color: var(--link-color);
  text-decoration: none;
  transition: color 0.3s ease;
}

.RomanEmpire a:hover {
  color: var(--link-hover-color);
}
.ProvincesSection {
  display: flex;
  align-items: center;
  gap: 20px;
}

.RomanEmpireMap {
  max-width: 40%; /* Adjust size as needed */
  border: 2px solid var(--heading-color);
  border-radius: 8px;
}