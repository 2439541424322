/* src/pages/RomanEmpireMapComponent.css */

.map-container {
    width: 100%;
    height: 700px; /* Increased height for better visibility */
    margin: 20px 0;
  }
  
  .leaflet-map {
    width: 100%;
    height: 100%;
  }
  